import * as React from "react";

export interface TopMenuItemProps {
    className: string;
    onMouseEnter: React.MouseEventHandler<HTMLLIElement>;
    onMouseLeave: React.MouseEventHandler<HTMLLIElement>;
    title: string;
    url: string;
}

export function TopMenuItem({ url, title, className, onMouseEnter, onMouseLeave }: TopMenuItemProps) {
    return (
        <li className={className} onMouseEnter={onMouseEnter} onMouseLeave={process.env.NODE_ENV !== "devel" ? onMouseLeave : undefined}>
            <a className="item-link" href={url}>
                {title}
            </a>
        </li>
    );
}
