import * as React from "react";
import { TopMenuItem, TopMenuItemProps } from "./TopMenuItem";
import { classNames } from "./utils";

export interface TopMenuProps {
    className: string;
    hover: number;
    itemClassName: string;
    items: Array<{
        title: string;
        url: string;
    }>;
    onHoverChanged: (index: number) => void;
    selected: string;
}

export function TopMenu({ items, selected, hover, className, itemClassName, onHoverChanged }: TopMenuProps) {
    return (
        <nav className={className} id="menu">
            <ul className="top-menu-in">
                {items.map((o, i, a) => {
                    const props: TopMenuItemProps = {
                        ...o,
                        className: classNames(itemClassName, {
                            first: i === 0,
                            hover: i === hover,
                            last: i === a.length - 1,
                            selected: o.url === selected,
                        }),
                        onMouseEnter: () => onHoverChanged(i),
                        onMouseLeave: () => onHoverChanged(-1),
                    };
                    return <TopMenuItem key={i} {...props} />;
                })}
            </ul>
        </nav>
    );
}
