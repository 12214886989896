import * as React from "react";

export interface EwImageOfObjectInfo {
    failImage?: string;
    filter: string;
    index?: number;
    objectIdentifier: string;
}

export interface EwImageOfObjectProps extends EwImageOfObjectInfo {
    alt?: string;
}

export class EwImageOfObject extends React.Component<EwImageOfObjectProps, never> {
    static getUrl({ objectIdentifier, filter, index, failImage }: EwImageOfObjectProps) {
        const urlParts = ["/ew/ew_images/image_of_object?ObjectIdentifier=", objectIdentifier, "&Filter=", filter];

        if (index) {
            urlParts.push("&ImageIndex=", index.toString());
        }

        if (failImage) {
            urlParts.push("&FailImage=", failImage);
        }

        return urlParts.join("");
    }
    render() {
        const { index, alt } = this.props;
        return <img alt={alt || "#" + index} src={EwImageOfObject.getUrl(this.props)} />;
    }
}
