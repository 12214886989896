import * as React from "react";
import { EwImageOfObject, EwImageOfObjectInfo } from "./EwImageOfObject";

export interface SubNavItemProps {
    className: string;
    image?: EwImageOfObjectInfo;
    onMouseEnter: React.MouseEventHandler<HTMLLIElement>;
    onMouseLeave: React.MouseEventHandler<HTMLLIElement>;
    title: string;
    url: string;
}

export function SubNavItem({ url, title, image, className, onMouseEnter, onMouseLeave }: SubNavItemProps) {
    return (
        <li className={className} onMouseEnter={onMouseEnter} onMouseLeave={process.env.NODE_ENV !== "devel" ? onMouseLeave : undefined}>
            <a className="item-link" href={url}>
                {image && (
                    <span className="item-image">
                        <EwImageOfObject {...image} />
                    </span>
                )}
                <strong className="item-title">{title}</strong>
            </a>
        </li>
    );
}
