import * as React from "react";
import * as ReactDOM from "react-dom";

import { Nullable } from "./types";

export function findByKey<T>(array: T[], predicate: (item: T) => boolean): Nullable<T>;
export function findByKey<T, K>(array: T[], predicate: (item: T) => boolean, selector?: (item: T) => K): Nullable<K>;
export function findByKey(array: any[], predicate: (item: any) => boolean, selector?: (item: any) => any): any {
    if (array) {
        for (const item of array) {
            if (predicate(item)) {
                return selector ? selector(item) : item;
            }
        }
    }

    return null;
}

type ClassValue = string | number | ClassDictionary | ClassArray | null | undefined;

interface ClassDictionary {
    [id: string]: boolean;
}

interface ClassArray extends Array<ClassValue> {}

export function classNames(...classes: ClassValue[]): string {
    const list = [];

    for (const cls of classes) {
        if (!cls) {
            continue;
        }

        if (typeof cls === "string" || typeof cls === "number") {
            list.push(cls);
        } else if (Array.isArray(cls)) {
            list.push(classNames.apply(null, cls));
        } else if (typeof cls === "object") {
            for (const key in cls) {
                if (cls.hasOwnProperty(key) && cls[key]) {
                    list.push(key);
                }
            }
        }
    }

    return list.join(" ");
}

const winKey = Symbol("___React_components").toString();

const win = window as { [key: string]: any };

interface ComponentInfo {
    [componentName: string]: React.ComponentClass<any> | React.StatelessComponent<any>;
}

win[winKey] = win[winKey] || {};
const components: ComponentInfo = win[winKey];

export function registerReactComponent<P>(component: React.ComponentClass<P> | React.StatelessComponent<P>, componentName: string) {
    components[componentName] = component;
}

export function getReactComponent<P>(componentName: string): React.ComponentClass<P> | React.StatelessComponent<P> {
    return components[componentName];
}

const onDOMContentLoaded = () => {
    document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);

    const keys = Object.keys(components);
    for (const key of keys) {
        const elements = document.querySelectorAll('[data-react="' + key + '"]') as any;
        for (const el of elements) {
            let props: any;
            const propsSelector = el.getAttribute("data-react-props");
            if (propsSelector) {
                props = win[propsSelector];
            }

            const div = document.createElement("div");
            div.className = el.className;
            if (el.parentElement) {
                el.parentElement.replaceChild(div, el);
            }
            ReactDOM.render(React.createElement(components[key] as any, props), div);
        }
    }
};

if (!win[winKey + "_initialized"]) {
    win[winKey + "_initialized"] = true;
    document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
}

export function groupBy<T, TKey>(array: T[], selector: (item: T) => TKey): Map<TKey, T[]> {
    const result = new Map<TKey, T[]>();

    for (const item of array) {
        const key = selector(item);
        let list: T[];
        if (result.has(key)) {
            list = result.get(key) as T[];
        } else {
            list = [];
            result.set(key, list);
        }

        list.push(item);
    }

    return result;
}

export function debounce<T extends { apply(context: any, args: any[]): any }>(func: T, wait: number, immediate?: boolean): T {
    let timeout: any;
    return function(this: any, ...args: any[]) {
        const context = this;
        const later = () => {
            clearTimeout(timeout);
            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    } as any;
}

type ClickOutsiteEventHandler = (e: MouseEvent) => void;

export class ClickOutsite {

    static subscribe(component: React.Component<any, any>, handler: ClickOutsiteEventHandler) {
        if (!ClickOutsite.subscribers.has(component)) {
            ClickOutsite.subscribers.set(component, handler);
        }

        if (ClickOutsite.subscribers.size === 1) {
            document.body.addEventListener("click", this.onClick);
        }
    }

    static unsubscribe(component: React.Component<any, any>, handler: ClickOutsiteEventHandler) {
        ClickOutsite.subscribers.delete(component);
        if (ClickOutsite.subscribers.size === 0) {
            document.body.removeEventListener("click", this.onClick);
        }
    }
    private static subscribers = new Map<React.Component<any, any>, ClickOutsiteEventHandler>();

    private static onClick = (e: MouseEvent) => {
        const target = e.target as Element;
        console.log(["click: ", target.tagName, target.id && "#" + target.id, target.className && "." + target.className.replace(/\s/g, ".")].join(""));

        ClickOutsite.subscribers.forEach((handler: ClickOutsiteEventHandler, component: React.Component<any, any>) => {
            const element = ReactDOM.findDOMNode(component);
            if (target === document.body.parentElement || (element !== target && !element.contains(target))) {
                handler(e);
            }
        });
    }
}

export function arraySimilar<T>(left: Nullable<T[]>, right: Nullable<T[]>): boolean {
    if ((!left && right) || (left && !right)) {
        return false;
    }

    const l = left as T[];
    let r = right as T[];

    if (l.length !== r.length) {
        return false;
    }

    r = r.slice();
    for (const part of l) {
        const index = r.indexOf(part);
        if (index === -1) {
            return false;
        }

        r.splice(index, 1);
    }

    return true;
}
