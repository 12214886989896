import * as React from "react";
import { SubMenuSubItemInfo } from "./Server";
import { SubNavItem, SubNavItemProps } from "./SubNavItem";
import { classNames } from "./utils";

export interface SubNavProps {
    className: string;
    itemClassName: string;
    items: SubMenuSubItemInfo[];
    selected: string;
}

interface SubNavState {
    hover: number;
}

export class SubNav extends React.PureComponent<SubNavProps, SubNavState> {
    constructor(props: SubNavProps) {
        super(props);
        this.state = { hover: -1 };
    }

    componentWillReceiveProps(nextProps: Readonly<SubNavProps>, nextContext: any) {
        if (this.props.items !== nextProps.items) {
            this.setState({ hover: -1 });
        }
    }

    render() {
        const { items, selected, className, itemClassName } = this.props;
        const { hover } = this.state;
        return (
            <div className={className}>
                <ul className={className + "-in"}>
                    {items.map((o, i, a) => {
                        const props: SubNavItemProps = {
                            ...o,
                            className: classNames(itemClassName, {
                                empty: !o,
                                first: i === 0,
                                hasImage: o && o.image !== undefined,
                                hover: i === hover,
                                last: i === a.length - 1,
                                selected: o && o.url === selected,
                            }),
                            onMouseEnter: () => this.updateState("ENTER", i),
                            onMouseLeave: () => this.updateState("LEAVE", i),
                        };
                        return <SubNavItem key={i} {...props} />;
                    })}
                </ul>
                <div className="cleaner" />
            </div>
        );
    }

    private updateState(action: "ENTER" | "LEAVE", index: number) {
        switch (action) {
            case "ENTER":
                this.setState({ hover: index });
                break;

            case "LEAVE":
                if (this.state.hover === index) {
                    this.setState({ hover: -1 });
                }
                break;
        }
    }
}
